import router from "@/router";
import { ref, onMounted } from "vue";

import { handleError } from "@/utils/errorHandler";
import UserService from "@/services/user_services";

export default {
  name: "AdminLogin",
  components: {},
  setup(props, context) {
    const data = ref({ email: undefined, password: undefined });
    const showPassword = ref(false);

    onMounted(async () => {});

    const loginByAuth = () => {
      UserService.signIn({
        email: data.value.email,
        password: data.value.password,
      })
        .then((response) => {
          // console.log("LOGIN : RETURNED_RESP :::", response.data);
          localStorage.token = response.data.token;
          localStorage.id = response.data._id;
          UserService.setDefaultHeader(response.data.token);
          router.push({ name: "Home" });
          context.emit("handleLogin");
        })
        .catch((error) => {
          handleError(context, error);
        });
    };
    onMounted(() => {
      // console.log(localStorage);
    });

    const logout = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      router.push({ name: "Home" });
    };

    return {
      data,
      showPassword,
      logout,
      loginByAuth,
    };
  },
};
